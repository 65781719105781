import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Button, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './App.scss';

import Logo from './logo.png';
import Background from './background.avif';

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [messageApi, contextHolder] = message.useMessage();

  const handleNotifyMe = async () => {
    setIsLoading(true);

    if (!email || email === '') {
      messageApi.error('Please enter your email');
      setIsLoading(false);
      return;
    }

    // validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      messageApi.error('Please enter a valid email');
      setIsLoading(false);
      return;
    }

    const date = new Date();
    const dateTimeString = date.toISOString();

    fetch('https://sheetdb.io/api/v1/vkbppxxsypxw4', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: [
          {
            'id': uuidv4(),
            'email': email,
            'timeStamp': dateTimeString
          }
        ]
      })
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error('Error:', error);
        messageApi.error('An error occurred. Please try again later.');
      })
      .finally(() => {
        setIsLoading(false);
        setEmail('');
        messageApi.success('Thank you! We will notify you when we launch.');
      })

  }

  return (
    <div className="App">
      {contextHolder}
      <div className="background-image">
        <img src={Background} alt="background" />
      </div>

      <div className='header'>
        <div className='header-background'></div>
        <div className='App-logo'>
          <img src={Logo} alt="logo" />
        </div>
      </div>
      <div className="container">
        <h1>Coming Soon!</h1>
        <p className='description'>Discover the beauty of Sri Lanka with our curated tours, personalized experiences, easy air ticket bookings, and comfortable accommodations.</p>
        <div className="email-signup">
          <input type="email" placeholder="Enter your email" required onChange={(e) => setEmail(e.target.value)} value={email} />
          <Button
            type="primary"
            onClick={handleNotifyMe}
          >
            {isLoading && <LoadingOutlined />}
            Notify Me
          </Button>
        </div>
        <p className='emailreach'>Got questions? Reach out to us at <a href="mailto:info@visitpearl.com.au">info@visitpearl.com.au</a></p>
      </div>
    </div>
  );
}

export default App;
